import React, { useRef, useState, useEffect } from 'react';
import classnames from 'classnames';
import { DragOverlay, useDndMonitor } from '@dnd-kit/core';

import { v4 as uuidv4 } from 'uuid';

import styles from './canvas.module.sass';
import { useAppState } from '../../../store/hooks/use-app';

import {
  useBubblesActions,
  useBubblesState,
} from '../../../store/hooks/use-bubbles';

import { DraggableCanvasBubble, CanvasBubble } from './canvas-bubble';
import { CANVAS_BUBBLE, DOCUMENT_BUBBLE } from '../../../types';

const handledEventTimestamps = [];

const Canvas = () => {
  const [draggedBubbles, setDraggedBubbles] = useState(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [selectionRect, setSelectionRect] = useState(null);

  const [open, setOpen] = useState(false);
  const togglePromptDialog = () => setOpen((o) => !o);
  const closeDialog = () => setOpen(false);

  const {
    focusedBubble: { viewMode },
  } = useBubblesState();
  const canvasRef = useRef();

  const {
    bubbles,
    focusedBubble: { id: currentCanvasId, canvasChildren },
    focusedBubbleId,
    selectedBubbles,
  } = useBubblesState();
  const {
    addDocumentBubble,
    addCanvasBubble,
    markSelectedBubbles,
    moveCanvasBubble,
    moveCanvasChildren,
    removeSelectedCanvasBubbles,
    deleteBubble,
    updateBubble,
    syncBubbles
  } = useBubblesActions();

  const populatedChildren = canvasChildren.map((b) => ({
    bubble: bubbles.find((fb) => fb.id === b.id),
    ...b,
  }));

  const InvalidateBubbles = () => {

    bubbles.map((b) => { const name = b.name; updateBubble({id: b.id, properties: {name} })})
    syncBubbles()
    console.log('OK')
  }
  
  window.InvalidateBubbles = InvalidateBubbles;

  // adding a bubble to canvas via doubleclick
  const onDoubleClickCanvas = (e) => {
    // get position of canvas in viewport
    const r = canvasRef.current?.getBoundingClientRect();

    const location = {
      x: e.clientX - r.left,
      y: e.clientY - r.top,
    };

    const newCanvasBubbleId = uuidv4();
    const newDocumentBubbleId = uuidv4();

    addCanvasBubble({
      id: newCanvasBubbleId,
      location,
    });

    addDocumentBubble({
      id: newDocumentBubbleId,
      parentBubbleId: newCanvasBubbleId,
    });
  };

  const onDragStart = (e) => {
    const { canvasIndex, canvasParentId, dimensions, location } =
      e.active?.data.current;

    // we are dragging a selected bubble, let's see if others are selected and drag them
    // with offset
    if (
      canvasParentId === currentCanvasId &&
      selectedBubbles?.includes(canvasIndex)
    ) {
      const r = canvasRef.current?.getBoundingClientRect();

      const selected = canvasChildren
        .map((b, i) => ({
          ...b,
          selected: selectedBubbles.includes(i),
          offset:
            canvasIndex === i
              ? { x: 0, y: 0 }
              : {
                  x: b.location.x - location.x,
                  y: b.location.y - location.y,
                },
          canvasIndex: i,
        }))
        .filter((b) => b.selected)
        .map((_b) => ({
          ..._b,
          ...bubbles.find((b) => b.id === _b.id),
        }));
      setDraggedBubbles(selected);
    } else {
      if (e.active?.data.current.type === DOCUMENT_BUBBLE) {
        // we must encapsulate the dragged document bubble within a canvas bubble
        // here, we just create a temporary one for the drag preview
        const temporaryCanvasBubble = {
          id: uuidv4(),
          name: '',
          documentChildren: [e.active?.data.current.bubble.id],
          canvasChildren: [],
        };
        setDraggedBubbles([temporaryCanvasBubble]);
      } else {
        const d = bubbles.find(
          (b) => b.id === e.active?.data.current.bubble.id,
        );
        setDraggedBubbles([
          {
            ...d,
            dimensions,
          },
        ]);
      }
    }
  };

  // handle moving bubbles on canvas and dragging to canvas
  const moveBubble = (e) => {
    const { active, delta, over } = e;
    const { id, type } = active.data.current;

    // get drop location
    const r = canvasRef.current?.getBoundingClientRect();
    const {
      activatorEvent: { clientX, clientY },
    } = e;
    const x = Math.max(clientX - r.left, 0);
    const y = Math.max(clientY - r.top, 0);

    const location = { x, y };

    switch (type) {
      case CANVAS_BUBBLE: {
        const { canvasIndex, canvasParentId } = active.data.current;

        if (canvasParentId === currentCanvasId) {
          // just moving bubble within current canvas
          // check whether multiple are selected, and move all of them
          if (selectedBubbles?.includes(canvasIndex)) {
            canvasChildren.forEach(({ id }, i) => {
              if (selectedBubbles.includes(i)) {
                moveCanvasBubble({ id, canvasIndex: i, delta });
              }
            });
          } else {
            moveCanvasBubble({ id, canvasIndex, delta });
          }
        } else if (!over) {
          moveCanvasChildren({
            canvasIndices: [canvasIndex],
            fromBubbleId: canvasParentId,
            toBubbleId: currentCanvasId,
            location,
          });
        }
        break;
      }
      case DOCUMENT_BUBBLE: {
        // TODO dirty hack: fix this, for now we are getting some duplicate events, just skip already handled ones

        const { timeStamp } = e.activatorEvent;
        if (handledEventTimestamps.includes(timeStamp)) {
          return;
        }

        // add document bubble inside a new canvas bubble, remove it from the source document
        const newCanvasBubbleId = uuidv4();
        addCanvasBubble({
          id: newCanvasBubbleId,
          location,
        });
        addDocumentBubble({
          id,
          parentBubbleId: newCanvasBubbleId,
        });
        active.data.current.onRemove();

        handledEventTimestamps.push(timeStamp);
        break;
      }
      default:
        console.error(`Drop not implemented in canvas for type: ${type}`);
        console.log(e.active);
    }
    setDraggedBubbles(null);
  };

  // handle painting & resizing

  const handleMouseDown = (e) => {
    if (e.target === e.currentTarget) {
      setIsDragging(true);
      setDragStart({ x: e.clientX, y: e.clientY });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    const selectionDiv = document.getElementById(styles.selection);
    if (selectionDiv) {
      selectionDiv.remove();
    }
    setSelectionRect(null);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    // create a div that shows the area that is being selected
    const deltaX = e.clientX - dragStart.x;
    const deltaY = e.clientY - dragStart.y;

    let selectionDiv = document.getElementById(styles.selection);
    if (!selectionDiv) {
      selectionDiv = document.createElement('div');
      selectionDiv.setAttribute('id', styles.selection);
      selectionDiv.classList.add(styles.selectionDiv);
      document.body.appendChild(selectionDiv);
    }

    // get canvas offset to correctly calculate selected bubbles
    const r = canvasRef.current?.getBoundingClientRect();
    const leftOffset = r.left;
    const topOffset = r.top;

    // Calculate left and top positions, width and height based on dragging direction
    const left = e.clientX < dragStart.x ? e.clientX : dragStart.x;
    const top = e.clientY < dragStart.y ? e.clientY : dragStart.y;
    const width = Math.abs(deltaX);
    const height = Math.abs(deltaY);

    selectionDiv.style.left = `${left}px`;
    selectionDiv.style.top = `${top}px`;
    selectionDiv.style.width = `${width}px`;
    selectionDiv.style.height = `${height}px`;

    // TODO: here, always just check things selected within the canvas
    // and mark them in the reducer
    // markSelected(selectionDiv.getBoundingClientRect());
    const rect = selectionDiv.getBoundingClientRect();
    markSelectedBubbles({
      top: rect.top - topOffset,
      left: rect.left - leftOffset,
      right: rect.right - leftOffset,
      bottom: rect.bottom - topOffset,
    });
  };

  const handleRemoveOnEsc = () => {
    removeSelectedCanvasBubbles();
  };
  const confirmDeleteBubble = (e) => {
    e.preventDefault();
    deleteBubble();
    closeDialog();
  };

  const handleKeyDown = (e) => {
    if (e?.key === 'Escape') {
      handleRemoveOnEsc();
    } else if (e?.key === 'Delete') {
      togglePromptDialog();
    } else {
    }
  };

  const dialogRef = useRef();
  useEffect(() => {
    if (open) {
      dialogRef?.current?.showModal();
    } else {
      dialogRef?.current?.close();
    }
  }, [open]);

  useDndMonitor({
    onDragStart,
    onDragEnd: moveBubble,
  });

  return (
    <div
      className={classnames(styles.canvas, styles[`${viewMode}ViewMode`])}
      id="canvas"
      onDoubleClick={onDoubleClickCanvas}
      ref={canvasRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onKeyDown={handleKeyDown}
      tabIndex="0"
    >
      <dialog ref={dialogRef} className="prompt-multiple-dialog">
        <h3>Do you really want to delete this bubble?</h3>
        <form>
          <div>
            <button type="submit" onClick={confirmDeleteBubble}>
              Yes
            </button>
            <button type="button" onClick={closeDialog}>
              Cancel
            </button>
          </div>
        </form>
      </dialog>
      {/* DEBUG {currentCanvasId} */}
      {populatedChildren.map(
        ({ bubble, location, dimensions, updateCount }, i) => {
          const isSelectedDragging = !!draggedBubbles?.find(
            (b) => b?.id === bubble.id && b?.canvasIndex === i,
          );
          return (
            <DraggableCanvasBubble
              updateCount={updateCount}
              canvasIndex={i}
              canvasParentId={currentCanvasId}
              key={`${bubble.id}-${i}`}
              bubble={bubble}
              location={location}
              dimensions={dimensions}
              isSelectedDragging={isSelectedDragging}
            />
          );
        },
      )}
      <DragOverlay dropAnimation={{ duration: 0 }}>
        <div className={styles.dragPreview}>
          {draggedBubbles?.map((draggedBubble) => {
            return (
              <CanvasBubble
                dimensions={draggedBubble.dimensions}
                key={draggedBubble.id}
                bubble={draggedBubble}
                offset={draggedBubble.offset}
                attached
              />
            );
          })}
        </div>
      </DragOverlay>
    </div>
  );
};

export default Canvas;
